import React from "react";

const EditIcon = (props: any) => {
  return (
    <>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.875 3.50071L7 1.62586L2 6.62545V8.50029H3.875L8.875 3.50071ZM10.355 2.02083C10.4481 1.92716 10.5004 1.80044 10.5004 1.66836C10.5004 1.53628 10.4481 1.40956 10.355 1.31589L9.185 0.145987C9.09132 0.0528692 8.96459 0.000602722 8.8325 0.000602722C8.70041 0.000602722 8.57368 0.0528692 8.48 0.145987L7.5 1.12591L9.375 3.00075L10.355 2.02083Z" fill={props?.color ?? "#464646"}  />
        <path d="M0 10.0002H12V12H0V10.0002Z" fill={props?.color ?? "#464646"} />
      </svg>
    </>
  );
};

export default EditIcon;


