import LoginPageImage from "../assets/loginPage.png";
import CompanyLogo from "../assets/logo.png";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {

  return (
    <>
      <div
        className={`w-full fixed top-0 bottom-0 right-0 left-0`}
        style={{ backgroundImage: `url(${LoginPageImage})` }}
      >
        <div
          className={`absolute top-0 right-0 bottom-0 w-[100%] md:w-[40%] bg-white flex justify-center items-center`}
        >
          <div className={`w-full text-center mx-5 m-auto`}>
            
              <img
                src={CompanyLogo}
                alt="Company Login"
                className={`m-auto -pb-14 h-24`}
              />
            <div className={`w-full mx-4 text-left`}></div>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
