import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { postApiData } from "../helpers/AxiosInstance";
import { useNavigate } from "react-router-dom";
interface IUsePostHook {
  queryKey: string[];
  navigateURL?: string;
}
const usePostHook = (props: IUsePostHook) => {
  const { queryKey, navigateURL } = props;
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    isPending: isPostLoading,
    mutateAsync,
    isSuccess: isPostSuccess,
    data: postData,
    isError: isPostError,
  } = useMutation({
    mutationFn: postApiData,
    onSuccess: async (data) => {
      if (data?.status === 201 || data?.status === 200) {
        toast.success(data?.data?.message ?? "Success");
        if (navigateURL) {
          navigate(navigateURL);
        }
      } else {
        if (data?.data?.non_field_errors) {
          toast.error(data?.data?.non_field_errors[0])
        } else {
          toast.error((data?.data?.message || data?.data?.detail) ?? "Something went wrong")
        }
      }
    },
    onError: (error) => {
      toast.error("Error");
    },

    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: queryKey,
        refetchType: 'active',
      },);
    },
  });
  return {
    isPostLoading,
    mutateAsync,
    isPostSuccess,
    postData,
    isPostError,
  };
};

export default usePostHook;