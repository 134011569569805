import React from 'react'
import loadable from '@loadable/component'

const TicketListComponent=loadable(
    ()=>import("../../components/Ticket/TicketList")
)
const TicketList = () => {
  return (
    <>
      <div
        className={"w-full flex justify-left gap-x-2 mb-6 uppercase font-extrabold items-center"}
      >
        {/* <RunsheetIcon /> */}
        <span className={"text-[#5E5757] font-bold text-xl"}>Ticket</span>
      </div>
    <TicketListComponent/>
    </>
  )
}

export default TicketList
