import { getKey } from "../helpers/sessionKey";

import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }:any) => {
  const token = getKey("token");
  if (!token) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default PrivateRoute;
