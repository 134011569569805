import React from 'react'
import loadable from '@loadable/component'
import APIS from '../../constant/EndPoint';
import usePatchHook from '../../customHooks/usePatchHook';
import useGetHook from '../../customHooks/useGetHook';
const FrontentServiesCompoenent =loadable(
    ()=>import("../../components/FrontentServices/FrontentServicelist")
)
const FrontentServiceslist = () => {

    const { mutateAsync: patchStatus, isLoading: isPatchLoading } = usePatchHook({
        queryKey: ["frontent-services"],
        navigateURL: "",
      });
      const { data: ForntendServiceData,isLoading } = useGetHook({
        queryKey: [`frontent-services`],
        url: `${APIS.FRONTENT_SERVICE}`,
        params: {},
      });
      // console.log(ForntendServiceData)
    const handleIsActive = async (value: boolean, id: any) => {
        const payload = {
          is_active: value,
        };
        const url = `${APIS.FRONTENT_SERVICE}${id}/`;
        const formData = payload;
        try {
          await patchStatus({ url, formData });
        } catch (e) {
          console.log(e);
        }
      };
  return (
    <div>
      <FrontentServiesCompoenent handleIsActive={handleIsActive} ForntendServiceData={ForntendServiceData} isLoading={isLoading}/>
    </div>
  )
}

export default FrontentServiceslist
