import React from 'react'

const EmailSetting = () => {
  return (
    <div>
      Email Setting
    </div>
  )
}

export default EmailSetting
