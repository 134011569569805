import loadable from '@loadable/component';
const ClientListComponent = loadable(
  () => import("../../components/Client/ClientList")
);

const ClientList = () => {
  return (
    <ClientListComponent />
  )
}

export default ClientList