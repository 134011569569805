import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { patchApiData } from "../helpers/AxiosInstance";

interface IUsePatchHook {
  queryKey: string[];
  navigateURL?: string;
}

const usePatchHook = (props: IUsePatchHook) => {
  const { queryKey, navigateURL } = props;
  const queryClient: any = useQueryClient();
  const navigate = useNavigate();

  const { isPending: isLoading, mutateAsync, isSuccess, data, isError } = useMutation({
    mutationFn: patchApiData,
    onSuccess: (data:any) => {
      if (data.status === 200) {
        toast.success("Updated successfully");
        if (navigateURL) {
          navigate(navigateURL);
        }
      } else {
        toast.error("Some things went wrongs");
      }
    },
    onError: (error) => {
      toast.error("Some things went wrongs");
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey ?? null);
    },
  }

  );
  return {
    isLoading,
    mutateAsync,
    isSuccess,
    data,
    isError,
  };
};

export default usePatchHook;