import React from "react";

const StatusIcon = (props: any) => {
  return (
    <>
      <svg
        width="23"
        height="24"
        viewBox="0 0 23 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.8125 11L12.4584 7.5L13.8 6.1L15.8125 8.2L19.8375 4L21.1792 5.4L15.8125 11ZM10.5417 7H1.91669V9H10.5417V7ZM20.125 13.4L18.7834 12L16.2917 14.6L13.8 12L12.4584 13.4L14.95 16L12.4584 18.6L13.8 20L16.2917 17.4L18.7834 20L20.125 18.6L17.6334 16L20.125 13.4ZM10.5417 15H1.91669V17H10.5417V15Z"
          fill={props?.color ?? "#464646"}
        />
      </svg>
    </>
  );
};

export default StatusIcon;
