//  set session key
export function setKey(key: string, value: string) {
  window.localStorage.setItem(key, value);
}

//  get value from session storage
export const getKey = (key: string) => {
  if (typeof window === "undefined") {
    return false;
  }
  if (localStorage.getItem(key)) {
    return localStorage.getItem(key);
  } else {
    return false;
  }
};

//  remove key from session storage
export const removeKey = (key: string) => {
  if (typeof window !== "undefined") {
    localStorage.removeItem(key);
  }
};

export const accessToken = () => {
  return getKey("token");
};

  // export const permissions = () => {
  //   const authToken = JSON.parse(getKey('userAuth'));
  //   const {accessToken, refreshToken, permissions, user} = authToken;
  //   return permissions
  // }
