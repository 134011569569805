import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import CreateIcon from "../../Icons/CreateIcon";
import { useParams } from "react-router-dom";
import useGetHook from "../../customHooks/useGetHook";
import APIS from "../../constant/EndPoint";
import Spinner from "../../components/Reusable/Spinner";
import usePostHook from "../../customHooks/usePostHook";
import { toast } from "react-toastify";
const AddServiceComponent = loadable(
  () => import("../../components/Client/ClientLayout/AddServices")
);
const AddClientServices = () => {
  const [defaultValue, setDefaultValue] = useState<any>([]);
  const { id } = useParams();
  const {mutateAsync,isPostLoading}=usePostHook({
    queryKey:[`client-services`],
    navigateURL:`/clients/submenu/client-services/${id}`,
  });
  const { data: ServiceData, isLoading: isServiceDataLoading } = useGetHook({
    queryKey: [`services`],
    url: `${APIS.SERVICE}`,
    params: {},
  });
  const { data: clientData, isLoading: isClientDataLoading } = useGetHook({
    queryKey: [`client-${id}`],
    url: `${APIS.CLIENT}${id}/`,
    params: {},
  });

 const handleAddClientServices=async(values:any,setError:any)=>{
    let formData={
      ...values,
    }
    try{
        let url=`${APIS.CLIENT}${id}/assign-service/`
        const res = await mutateAsync({ url, formData ,setError});

      } catch (e) {
        console.log(e);
        toast.error("Somethings went wrongs");
      }
    };
  const handleChangeServices = (value: any) => {
    setDefaultValue(value);
  };
  return (
    <>
      {isClientDataLoading ? (
        <Spinner />
      ) : (
        <div>
          <div
            className={`w-full flex justify-left gap-x-2 mb-6 uppercase font-extrabold items-center`}
          >
            <CreateIcon />
            <span className={`text-[#5E5757] font-bold text-xl`}>
              Add Services
            </span>
          </div>
          <AddServiceComponent
            handleChangeServices={handleChangeServices}
            defaultValue={defaultValue}
            handleAddClientService={handleAddClientServices}
            clientData={clientData}
            setDefaultValue={setDefaultValue}
            ServiceData={ServiceData}
          />
        </div>
      )}
    </>
  );
};

export default AddClientServices;
