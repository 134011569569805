import React from "react";


const Spinner = () => {
  return (
    <>
      <div className={`w-full relative p-3`}>
        <div
          className={`absolute mx-auto right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2`}
        >
          <div
            className={`border-t-transparent border-solid animate-spin  rounded-full border-primary border-4 h-8 w-8`}
          ></div>
        </div>
      </div>
    </>
  );
};

export default Spinner;
