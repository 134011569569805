import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import useGetHook from "../../customHooks/useGetHook";
import APIS from "../../constant/EndPoint";
import Spinner from "../../components/Reusable/Spinner";
import { useParams } from "react-router-dom";
import useDeleteHook from "../../customHooks/useDeleteHook";
import usePatchHook from "../../customHooks/usePatchHook";
const ClientServiceComponent = loadable(
  () => import("../../components/Client/ClientLayout/ClinetService")
);
const ClientServices = () => {
  const { id } = useParams();
  const { mutateAsync: deleteservice, isLoading: isDeleteloading } =
    useDeleteHook({
      queryKey: ["services"],
      navigateURL: "",
    });
  const { mutateAsync: patchStatus, isLoading: isPatchLoading } = usePatchHook({
    queryKey: ["services"],
    navigateURL: "",
  });
  const handleDeleteService = async (id: any) => {
    const url = `${APIS.CLIENT_SERVICE}${id}/`;
    try {
      await deleteservice({ url });
    } catch (e) {
      console.log(e);
    }
  };
  const handleIsActive = async (value: boolean, id: any) => {
    const payload = {
      is_active: value,
    };
    const url = `${APIS.CLIENT_SERVICE}${id}/`;
    const formData = payload;
    try {
      await patchStatus({ url, formData });
    } catch (e) {
      console.log(e);
    }
  };
  const handleIsNewTabOpen = async (value: boolean, id: any) => {
    const payload = {
      open_in_new_tab: value,
    };
    const url = `${APIS.CLIENT_SERVICE}${id}/`;
    const formData = payload;
    try {
      await patchStatus({ url, formData });
    } catch (e) {
      console.log(e);
    }
  };
  const handleIsDefault = async (value: boolean, id: any) => {
    const payload = {
      default: value,
    };
    const url = `${APIS.CLIENT_SERVICE}${id}/toggle-default/`;
    const formData = payload;
    try {
      await patchStatus({ url, formData });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div>
      <ClientServiceComponent
        id={id}
        handleDeleteService={handleDeleteService}
        handleIsActive={handleIsActive}
        handleIsNewTabOpen={handleIsNewTabOpen}
        handleIsDefault={handleIsDefault}
      />
    </div>
  );
};

export default ClientServices;
