import React, { useState } from "react";
import loadable from "@loadable/component";
import CreateIcon from "../../Icons/CreateIcon";
import { useParams } from "react-router-dom";
import usePostHook from "../../customHooks/usePostHook";
import APIS from "../../constant/EndPoint";
import { toast } from "react-toastify";
const CreateSubDomainComponent = loadable(
  () => import("../../components/Client/ClientLayout/CreateSubDomain")
);
const CreateSubDomain = () => {
  const { id } = useParams();
  const [is_subDomain, SetIssubDomain] = useState(false);
  const [domain, setDomain] = useState("");
  const { mutateAsync, isPostLoading } = usePostHook({
    queryKey: [`sub-domain`],
    navigateURL: `/clients/submenu/domain/${id}`,
  });
  const handleCreateSubDomain = async (values: any, setError: any) => {
    let formData = {
      sub_domain: is_subDomain ? `${domain}.careinfosloutions.com` : values?.sub_domain,
    };
    try {
      let url = `${APIS.CLIENT}${id}/add-sub-domain/`;
      const res = await mutateAsync({ url, formData, setError });
    } catch (e) {
      console.log(e);
      toast.error("Somethings went wrongs");
    }
  };

  return (
    <>
      <div
        className={`w-full flex justify-left gap-x-2 mb-6 uppercase font-extrabold items-center`}
      >
        <CreateIcon />
        <span className={`text-[#5E5757] font-bold text-xl`}>
          Create Sub Domain
        </span>
      </div>
      <CreateSubDomainComponent
        handleCreateSubDomain={handleCreateSubDomain}
        is_subDomain={is_subDomain}
        SetIssubDomain={SetIssubDomain}
        domain={domain}
        setDomain={setDomain}
      />
    </>
  );
};

export default CreateSubDomain;
