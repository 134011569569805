import React from "react";
import loadable from "@loadable/component";
import useGetHook from "../../customHooks/useGetHook";
import { useParams } from "react-router-dom";
import APIS from "../../constant/EndPoint";
import Spinner from "../../components/Reusable/Spinner";
import EditIcon from "../../Icons/EditIcons";
import usePatchHook from "../../customHooks/usePatchHook";
import { toast } from "react-toastify";
const EditDomainComponent = loadable(
  () => import("../../components/Domain/EditDomain")
);

const EditDomain = () => {
  const { id } = useParams();
  const { data: DomainData, isLoading } = useGetHook({
    queryKey: [`domain-${id}`],
    url: `${APIS.DOMAIN}${id}/`,
    params: {},
  });
  const { mutateAsync, isLoading:ispatchLoading } = usePatchHook({
    queryKey: [`domain-${id}`],
    navigateURL: "/domains?limit=10&page=0",
  });
  const handleEditDomain = async (values: any, setError: any) => {
    let formData = {
      ...values,
    };
    try {
      let url = `${APIS.DOMAIN}${id}/`;
      const res = await mutateAsync({ url, formData, setError });
    } catch (e) {
      console.log(e);
      toast.error("Somethings went wrongs");
    }
  };
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <div
            className={`w-full flex justify-left gap-x-2 mb-6 uppercase font-extrabold items-center`}
          >
            <EditIcon />
            <span className={`text-[#5E5757] font-bold text-xl`}>
              Edit Domain
            </span>
          </div>
          <div>
            <EditDomainComponent DomainData={DomainData}  handleEditDomain={handleEditDomain}/>
          </div>
        </div>
      )}
    </>
  );
};

export default EditDomain;
