import loadable from '@loadable/component';
import APIS from '../../constant/EndPoint';
import usePostHook from '../../customHooks/usePostHook';
const ConfirmPassordResetComponent = loadable(
  () => import("../../components/Auth/ConfirmPasswordReset")
);
const ConfirmPassordReset = () => {
  const { mutateAsync, isPostLoading } = usePostHook({
    queryKey: ["confirm-password"],
    navigateURL: `/login`,
  });

  const handlePasswordReset = async (values: any, setError: any) => {
    const url = `${APIS.FORGET_PASSWORD}`;
    let formData = {
      ...values,
    };
    try {
      await mutateAsync({ url, formData, setError });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <ConfirmPassordResetComponent
      handlePasswordReset={handlePasswordReset}
    />
  )
}

export default ConfirmPassordReset
