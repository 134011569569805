import React from 'react'
import loadable from '@loadable/component'
import EditIcon from '../../Icons/EditIcons'
import usePatchHook from '../../customHooks/usePatchHook'
import APIS from '../../constant/EndPoint'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import useGetHook from '../../customHooks/useGetHook'
import Spinner from '../../components/Reusable/Spinner'
const EditFrontendServiceComponent=loadable(
    ()=>import("../../components/FrontentServices/EditFrontentService")
)
const EditFornentService = () => {
    const {id}=useParams();
    const { mutateAsync, isLoading } = usePatchHook({
        queryKey: [`frontend-services-${id}`],
        navigateURL: "/frontent-services",
      });
      const { data: FrontendServiceData, isLoading: isFrontendDataLoading } = useGetHook({
        queryKey: [`frontent-service-${id}`],
        url: `${APIS.FRONTENT_SERVICE}${id}/`,
        params: {},
      });
      const handleEdit = async (values: any, setError: any) => {
        let formData = {
          ...values,
        };
        try {
          let url = `${APIS.FRONTENT_SERVICE}${id}/`;
          const res = await mutateAsync({ url, formData, setError });
        } catch (e) {
          console.log(e);
          toast.error("Somethings went wrongs");
        }
      };
  return (
    <div>
           <div
        className={`w-full flex justify-left gap-x-2 mb-6 uppercase font-extrabold items-center`}
      >
        <EditIcon />
        <span className={`text-[#5E5757] font-bold text-xl`}>
          Edit Frontend Services
        </span>
      </div>
      {isFrontendDataLoading ? (<Spinner/>):(
      <EditFrontendServiceComponent handleEdit={handleEdit} FrontendServiceData={FrontendServiceData} />
    )}
    </div>
  )
}

export default EditFornentService
