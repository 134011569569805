import { Menu, Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import UserIcon from "../assets/profile.png";
import { Link } from "react-router-dom";
import { removeKey } from "../helpers/sessionKey";


export default function Logout(props:any) {
  const handleLogout = () => {
    removeKey("token");
    window.location.replace("/login")
  };
  const {profile}=props;
  return (
    <div className={`text-right`}>
    <Popover className={`relative`}>
      {({ open }) => (
        <>
          <Popover.Button
            className={`
              ${open ? `text-white` : `text-white`}
              // group inline-flex items-center rounded-md  px-3 py-2 text-base font-medium hover:text-white focus:outline-none `}
          >
            <img
              src={UserIcon}
              alt="User Icon"
              className={`cursor-pointer h-14 w-14`}
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={`absolute z-10 mt-3  max-w-sm w-[350px] px-4 -mx-[280px]`}
            >
              <div
                className={` text-black flex flex-col justify-center items-center gap-y-4   overflow-hidden rounded-lg px-2 py-4 shadow-lg w-full bg-[#C5F9FA]`}
              >
                 <div className={` font-semibold`}>{profile?.full_name}</div>
                <img
                  src={UserIcon}
                  alt="User Icon"
                  className={`cursor-pointer h-16 w-16`}
                />
                <div className={`flex flex-col items-center`}>
                 
                <div className={`text-sm`}>{profile?.email}</div>
                  <Link
                    to="/profile"
                    className={`px-2 py-1 rounded-lg border-2 border-gray-400 text-primary hover:bg-[#FFBBDC]`}
                  >
                    Manage Account
                  </Link>
                </div>
                <button
                  onClick={handleLogout}
                  className={`text-black flex rounded-lg px-3 py-2 text-sm cursor-pointer bg-white`}
                >
                  Logout
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.33366 1.33325C2.96549 1.33325 2.66699 1.63171 2.66699 1.99992V13.9999C2.66699 14.3681 2.96549 14.6666 3.33366 14.6666H11.3337C11.7018 14.6666 12.0003 14.3681 12.0003 13.9999V12.6666C12.0003 12.2984 11.7018 11.9999 11.3337 11.9999C10.9655 11.9999 10.667 12.2984 10.667 12.6666V13.3333H4.00033V2.66659H10.667V3.33325C10.667 3.70146 10.9655 3.99992 11.3337 3.99992C11.7018 3.99992 12.0003 3.70146 12.0003 3.33325V1.99992C12.0003 1.63171 11.7018 1.33325 11.3337 1.33325H3.33366Z"
                      fill="#59564E"
                    />
                    <path
                      d="M9.36719 4.70007C9.62752 4.43974 10.0496 4.4397 10.31 4.70007L13.1384 7.52848C13.2059 7.59599 13.2559 7.67432 13.2884 7.75793C13.3176 7.83293 13.3337 7.91455 13.3337 7.99992C13.3337 8.09464 13.3139 8.18473 13.2783 8.26632C13.2514 8.32812 13.2147 8.38668 13.168 8.43966C13.1575 8.4517 13.1465 8.46338 13.1351 8.47465L10.31 11.2997C10.0496 11.5601 9.62752 11.5601 9.36719 11.2997C9.10677 11.0393 9.10685 10.6172 9.36719 10.3569L11.0575 8.66658H6.00033C5.63216 8.66658 5.33366 8.36812 5.33366 7.99992C5.33366 7.63171 5.63216 7.33325 6.00033 7.33325H11.0575L9.36719 5.64286C9.10677 5.38253 9.10685 4.96041 9.36719 4.70007Z"
                      fill="#59564E"
                    />
                  </svg>
                </button>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  </div>
  );
}