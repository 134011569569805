import loadable from "@loadable/component";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import APIS from "../../constant/EndPoint";
import usePostHook from "../../customHooks/usePostHook";

const ForgetPasswordComponent = loadable(
  () => import("../../components/Auth/ForgetPassword")
);
const PasswordReset = () => {
  const { mutateAsync, isPostLoading } = usePostHook({
    queryKey: ["login-user"],
    navigateURL: `/confirm-password-reset`,
  });

  const handlePasswordReset = async (values: any, setError: any) => {
    const url = `${APIS.PASSWORD_RESET_SEND_OTP}`;
    let formData = {
      ...values,
    };
    try {
      await mutateAsync({ url, formData, setError });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <ForgetPasswordComponent
        handlePasswordReset={handlePasswordReset}
      />
    </>
  );
};

export default PasswordReset;
