import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { getApiData } from "../helpers/AxiosInstance";

interface IUseGetHook {
  queryKey: string[];
  url: string;
  params: any;
}

const useGetHook = (props: IUseGetHook) => {
  const { queryKey, url, params } = props;
  const { isLoading, isError, data, isSuccess, refetch } = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      const response = await getApiData(url, params);
      return response.data;
    },
    refetchOnWindowFocus: true,
    placeholderData: keepPreviousData
  }
  );

  return { isLoading, isError, data, isSuccess, refetch };
};

export default useGetHook;