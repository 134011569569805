import React, { Fragment } from 'react'
import { NavLink, Outlet, useLocation } from "react-router-dom";
import useGetHook from '../customHooks/useGetHook';
import { Popover, Transition } from '@headlessui/react';
import APIS from '../constant/EndPoint';
import CompanyLogo from "../assets/logo.png";
import Logout from './Logout';
import OverViewIcon from "../Icons/OverViewIcon";

export const navigation: any = [
  {
    name: "Dashboard",
    href: "/",
    icon: "",
    children: [
      {
        name: "Overview",
        href: "/",
        icon: OverViewIcon,
        activeIcon: OverViewIcon,
      },
    ],
  },
  {
    name: "Operations",
    href: "/",
    icon: "",
    children: [
      {
        name: "Clients",
        href: "/clients?limit=10&page=0",
        icon: OverViewIcon,
        activeIcon: OverViewIcon,
        children: [],
      },
      {
        name: "Domains",
        href: "/domains?limit=10&page=0",
        icon: OverViewIcon,
        activeIcon: OverViewIcon,
        children: [],
      },
      {
        name: "Services",
        href: "/services",
        icon: OverViewIcon,
        activeIcon: OverViewIcon,
        children: [],
      },
      {
        name: "Frontend Services",
        href: "/frontent-services",
        icon: OverViewIcon,
        activeIcon: OverViewIcon,
        children: [],
      },
      {
        name: "Ticket",
        href: "/ticket",
        icon: OverViewIcon,
        activeIcon: OverViewIcon,
        children: [],
      },
    ],
  },
  {
    name: "Administration",
    href: "/",
    icon: "",
    children: [
      // {
      //   name: "Roles",
      //   href: "/roles?limit=10&page=0",
      //   icon: OverViewIcon,
      //   activeIcon: OverViewIcon,
      // },
      {
        name: "Users",
        href: "/users?limit=10&page=0",
        icon: OverViewIcon,
        activeIcon: OverViewIcon,
      },
    ],
  },
];


const DashboardLayout = () => {
  const location = useLocation();

  const { data: profile } = useGetHook({
    queryKey: [`profile`],
    url: `${APIS.USER}me/`,
    params: {},
  });
 
  return (
    <>
      <div className={`flex h-[100vh] overflow-hidden`}>
        <div className={`h-full w-64`}>
          <div className={`h-[65px] w-full bg-primary flex items-center justify-center`}>
            <img
              className={`h-12`}
              src={CompanyLogo}
              alt="Careinfo Solutions"
            />
          </div>
          <div className={`h-[calc(100%-65px)] p-1 hover:overflow-auto`}>
            <div className={`rounded-md h-full overflow-auto px-2`} style={{ background: "linear-gradient(135deg, #95ffff, rgba(246, 172, 208, 0.03) 82.72%, rgba(248, 206, 226, 0) 100%)" }}>
              <ul>
                {navigation?.map((item: any, index: number) => (
                  <li key={index}>
                    <span className={`pt-2 block font-[18px] mt-6 text-gray-500`}>
                      {item.name}
                    </span>
                    {item.children.length && (
                      <>
                        <ul className={`ml-2`}>
                          {item.children.map((subMenu: any, subIndex: number) => (
                            <React.Fragment key={`subIndex-${subIndex}`}>
                              <NavLink
                                key={subIndex}
                                to={subMenu.href}
                                className={({ isActive, isPending }) =>
                                  `${isActive
                                    ? `bg-[#2fffff] rounded text-primary`
                                    : isPending
                                      ? `text-indigo-100 hover:bg-indigo-600`
                                      : ""
                                    } text-gray-700 my-1 cursor-pointer px-2 py-[4px] flex `
                                }
                              >
                                <subMenu.icon
                                  color={
                                    location.pathname === subMenu.href
                                      ? "var(--primary)"
                                      : "gray"
                                  }
                                />
                                <span className={`ml-4`}>{subMenu.name}</span>
                              </NavLink>
                            </React.Fragment>
                          ))}
                        </ul>
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={`h-full w-full`}>
          <div className={`h-[65px] w-full bg-primary flex justify-end items-center`}>
            <div className={`max-w-sm px-4`}>
              <Popover className={`relative`}>
                {({ open }) => (
                  <>
                    <div className={`absolute right-0 text-red-700 font-bold -top-1 px-2 bg-white rounded-full`}>{"0"}</div>
                    <Popover.Button
                      className={`
                ${open ? `text-white` : `text-white`}
                group inline-flex items-center rounded-md  px-3 py-2 text-base font-medium hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white`}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke={"white"} className={`w-6 h-6`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                      </svg>
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className={`absolute z-10 mt-3  max-w-sm w-[350px] -mx-[105px] md:mx-[-290px] px-4 sm:px-0`}>
                        <div className={`overflow-hidden rounded-lg shadow-lg ring-1 ring-black w-full`}>
                          <div className={`relative grid gap-8 bg-white p-4`}>
                            
                            <div className={`relative bg-white text-black mx-4 w-full flex justify-center gap-x-6 cursor-pointer`}>
                              {/* <button onClick={handleReadAllNotification}>Read All</button> <Link to={"notification?limit=0&offset=0"}>View All</Link> */}
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div>
            <div className={`text-white flex relative`}>
              <div className={`flex justify-center items-center`}>
                <span className={`text-[10px] mr-2 block text-right`}>
                  <span className={`block capitalize`}>{profile?.full_name}</span>
                  <span className={`block`}>{profile?.is_superuser ? "Super Admin" :  "Staff"}</span>
                </span>
              </div>
              <div className="">
                <Logout profile={profile}/>
              </div>
            </div>

          </div>
          <div className={`h-[calc(100%-95px)] overflow-auto p-2`}>
            <Outlet/>
          </div>
          <div className={`h-[30px] text-gray-700 flex items-center w-full bg-primary p-2 text-center`}>
            <h1>@copy CareInfo Solutions</h1>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardLayout