import React from 'react'
import loadable from '@loadable/component';
import usePostHook from '../../customHooks/usePostHook';
import APIS from '../../constant/EndPoint';
import { toast } from 'react-toastify';
const OverViewComponent = loadable(
  () => import("../../components/OverView")
);

const OverView = () => {
  const { mutateAsync, isPostLoading } = usePostHook({
    queryKey: [`clearcached`],
    navigateURL: "",
  });
  const handleClearCache=async()=>{
    try {
      let url = `${APIS.CACHED}`;
      const res = await mutateAsync({ url});
    } catch (e) {
      console.log(e);
      toast.error("Somethings went wrongs");
    }
  }
  return (
    <OverViewComponent handleClearCache={handleClearCache} isPostLoading={isPostLoading}/>
  )
}

export default OverView