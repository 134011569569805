import React from "react";
import loadable from "@loadable/component";
import CreateIcon from "../../Icons/CreateIcon";
import APIS from "../../constant/EndPoint";
import usePostHook from "../../customHooks/usePostHook";
import { toast } from "react-toastify";
const FrontendServiceCreateComponent = loadable(
  () => import("../../components/FrontentServices/FrontendServiceCreate")
);
const FrontendServiceCreate = () => {
  const { mutateAsync, isPostLoading } = usePostHook({
    queryKey: ["frontend-services"],
    navigateURL: "/frontent-services",
  });
  const handleCreate = async (values: any, setError: any) => {
    let formData = {
      ...values,
    };
    try {
      let url = `${APIS.FRONTENT_SERVICE}`;
      const res = await mutateAsync({ url, formData, setError });
    } catch (e) {
      console.log(e);
      toast.error("Somethings went wrongs");
    }
  };
  return (
    <div>
      <div
        className={`w-full flex justify-left gap-x-2 mb-6 uppercase font-extrabold items-center`}
      >
        <CreateIcon />
        <span className={`text-[#5E5757] font-bold text-xl`}>
          Create Frontend Services
        </span>
      </div>
      <FrontendServiceCreateComponent handleCreate={handleCreate} />
    </div>
  );
};

export default FrontendServiceCreate;
