import React from "react";

const OverViewIcon = (props: any) => {
  return (
    <div>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 15.5H9V13.5H16V15.5ZM19 11.5H9V9.5H19V11.5ZM19 7.5H9V5.5H19V7.5ZM3 5.5V21.5H19V23.5H3C2.46957 23.5 1.96086 23.2893 1.58579 22.9142C1.21071 22.5391 1 22.0304 1 21.5V5.5H3ZM21 1.5C21.5304 1.5 22.0391 1.71071 22.4142 2.08579C22.7893 2.46086 23 2.96957 23 3.5V17.5C23 18.61 22.11 19.5 21 19.5H7C6.46957 19.5 5.96086 19.2893 5.58579 18.9142C5.21071 18.5391 5 18.0304 5 17.5V3.5C5 2.39 5.89 1.5 7 1.5H21ZM7 3.5V17.5H21V3.5H7Z"
          fill={props?.color ?? "black"}
        />
      </svg>
    </div>
  );
};

export default OverViewIcon;
