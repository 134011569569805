import React from "react";
import loadable from "@loadable/component";
import StatusIcon from "../../Icons/StatusIcon";
import usePostHook from "../../customHooks/usePostHook";
import APIS from "../../constant/EndPoint";
import useGetHook from "../../customHooks/useGetHook";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Reusable/Spinner";

const ClientSubcriptionComponent = loadable(
  () => import("../../components/Client/ClientSubcription")
);
const ClientSubcription = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetHook({
    queryKey: ["subscription"],
    url: APIS.SUBSCRIPTION,
    params: {
      client: id,
    },
  });

  const { mutateAsync, isPostLoading } = usePostHook({
    queryKey: ["subscription"],
    navigateURL: "",
  });
  const handleExtend = async (values: any, setError: any) => {
    let formData = {
      ...values,
      client: id,
    };
    const url = `${APIS.SUBSCRIPTION}extend-month/`;
    try {
      await mutateAsync({ url, formData, setError });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div
        className={`w-full flex justify-left gap-x-2 mb-6 uppercase font-extrabold items-center`}
      >
        <StatusIcon />
        <span className={`text-[#5E5757] font-bold text-xl`}>Subcription</span>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <ClientSubcriptionComponent
          isPostLoading={isPostLoading}
          handleExtend={handleExtend}
          data={data}
        />
      )}
    </>
  );
};

export default ClientSubcription;
