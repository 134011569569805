import React from "react";
import loadable from "@loadable/component";
import APIS from "../../constant/EndPoint";
import usePatchHook from "../../customHooks/usePatchHook";
import useDeleteHook from "../../customHooks/useDeleteHook";
const ServicesListComponent = loadable(
  () => import("../../components/Services/Servicelist")
);
const Servicelist = () => {
  const { mutateAsync: patchStatus, isLoading: isPatchLoading } = usePatchHook({
    queryKey: ["services"],
    navigateURL: "",
  });
  const { mutateAsync: deleteservice, isLoading: isDeleteloading } =
    useDeleteHook({
      queryKey: ["services"],
      navigateURL: "",
    });
  const handleDeleteService = async (id: any) => {
    const url = `${APIS.SERVICE}${id}/`;
    try {
      await deleteservice({ url });
    } catch (e) {
      console.log(e);
    }
  };
  const handleIsActive = async (value: boolean, id: any) => {
    const payload = {
      is_active: value,
    };
    const url = `${APIS.SERVICE}${id}/`;
    const formData = payload;
    try {
      await patchStatus({ url, formData });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ServicesListComponent
      handleIsActive={handleIsActive}
      handleDeleteService={handleDeleteService}
    />
  );
};

export default Servicelist;
