import React from 'react'
import loadable from '@loadable/component';
import APIS from '../../constant/EndPoint';
import StatusIcon from '../../Icons/StatusIcon';
import usePatchHook from '../../customHooks/usePatchHook';
const UserListComponent = loadable(
  () => import("../../components/User/UserList")
);

const UserList = () => {
  const { mutateAsync: patchUser } = usePatchHook({ queryKey: ["users"] })

  const handleIsActive = async (value: boolean, id: any) => {
    const payload = {
      is_active: value
    };
    const url = `${APIS.USER}${id}/`;
    const formData = payload
    try {
      await patchUser({ url, formData });

    } catch (e) {
      console.log(e)
    }
  }

  const handleIsStaff = async (value: boolean, id: any) => {
    const payload = {
      is_staff: value
    };

    const url = `${APIS.USER}${id}/`;
    const formData = payload
    try {
      await patchUser({ url, formData });

    } catch (e) {
      console.log(e)
    }
  }

  const handleIsSuperUser = async (value: any, id: any) => {
    const payload = {
      is_superuser: value
    };
    const url = `${APIS.USER}${id}/`;
    const formData = payload
    try {
      await patchUser({ url, formData });

    } catch (e) {
      console.log(e)
    }
  }
  return (
    <>
      <div className="w-full flex justify-left gap-x-2 mb-6 uppercase font-extrabold items-center">
        <StatusIcon />
        <span className="text-[#5E5757] font-bold text-xl">Users</span>
      </div>
      <UserListComponent
        handleIsActive={handleIsActive}
        handleIsStaff={handleIsStaff}
        handleIsSuperUser={handleIsSuperUser}
      />
    </>
  )
}

export default UserList