import { toast } from "react-toastify";
import { accessToken } from "./sessionKey";
import Axios from "axios";

const API_ROOT: string = "https://tenantcontrolservice.careinfosolutions.com/api";

export default API_ROOT;
//create axios instance
export const instance = Axios.create({
  baseURL: `${API_ROOT}`,
  headers: {
    "Content-Type": "application/json",
    Authorization: `JWT ${accessToken()}`,
  },
});

instance.interceptors.request.use(
  function (config: any) {
    config.headers = {
      'x-requested-with': '',
      authorization: accessToken() ? `JWT ${accessToken()}` : null,
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { response } = error;
    if (response) {
      if (response.status === 404) {
        toast.error('Error');
      } else if (response.status === 401) {
        if (window.location.pathname !== "/login") {
          window.location.replace("/login")
        }
      } else {
        toast.error(response.data.msg);
      }
    }

    return Promise.reject(error);
  },
);


// Get data from API
export const getApiData = async (url: string, param = null) => {
  let response;
  try {
    response = await instance({
      method: "GET",
      url: `${url}`,
      params: param,
      transformResponse: [
        function (responseData: any) {
          // Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e: any) {
    return e.response;
  }
  return response;
};

// Post data to API
export const postApiData = async (data: any) => {
  const { url, formData, setError } = data;
  let response;
  try {
    response = await instance({
      method: "POST",
      url: `${url}`,
      data: formData,
      transformResponse: [
        function (responseData: any) {
          // Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e: any) {
    for (const key in e.response?.data) {
      if (e.response?.data.hasOwnProperty(key)) {
        if (key !== "detail") {
          setError(key, { type: 'custom', message: e.response?.data[key] });
        }
      }
    }
    return e.response;
  }
  return response;
};
export const patchApiData = async (data: any) => {
  const { url, formData, setError } = data;
  let response;
  try {
    response = await instance({
      method: "PATCH",
      url: `${url}`,
      data: formData,
      headers: {
        Authorization: `JWT ${accessToken()}`,
      },
      transformResponse: [
        function (responseData: any) {
          //Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e: any) {
    for (const key in e.response?.data) {
      if (e.response?.data.hasOwnProperty(key)) {
        setError(key, { type: 'custom', message: e.response?.data[key] });
      }
    }
    return e.response;
  }
  return response;
};
export const deleteApiData = async (data: any) => {
  const { url, formData, setError } = data;
  let response;
  try {
    response = await instance({
      method: "DELETE",
      url: `${url}`,
      data: formData,
      headers: {
        Authorization: `JWT ${accessToken()}`,
      },
      transformResponse: [
        function (responseData: any) {
          //Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e: any) {
    for (const key in e.response?.data) {
      if (e.response?.data.hasOwnProperty(key)) {
        setError(key, { type: 'custom', message: e.response?.data[key] });
      }
    }
    return e.response;
  }
  return response;
};
// create form data to upload files
export const patchApiFormData = async (data: any) => {
  const { url, formData, setError } = data;
  let response;
  try {
    response = await instance({
      method: "PATCH",
      url: `${url}`,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data`,

      },
      transformResponse: [
        function (responseData: any) {
          //Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e: any) {
    for (const key in e.response?.data) {
      if (e.response?.data.hasOwnProperty(key) && setError) {
        setError(key, { type: 'custom', message: e.response?.data[key] });
      }
    }
    return e.response;
  }
  return response;
};

// create form data to upload files
export const postApiFormData = async (data: any) => {
  const { url, formData, setError } = data;
  let response;
  try {
    response = await instance({
      method: "POST",
      url: `${url}`,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data`,
      },
      transformResponse: [
        function (responseData: any) {
          //Do whatever you want to transform the data
          return JSON.parse(responseData);
        },
      ],
    });
  } catch (e: any) {
    for (const key in e.response?.data) {
      if (e.response?.data.hasOwnProperty(key) && setError) {
        setError(key, { type: 'custom', message: e.response?.data[key] });
      }
    }
    return e.response;
  }
  return response;
};


export { API_ROOT };