import React from "react";
import loadable from "@loadable/component";
import useDeleteHook from "../../customHooks/useDeleteHook";
import APIS from "../../constant/EndPoint";
import { useParams } from "react-router-dom";
const DomainListComponent = loadable(
  () => import("../../components/Domain/DomainList")
);

const DomainList = () => {
  // const {id}=useParams();
  const { mutateAsync: deleteservice, isLoading: isDeleteloading } =
    useDeleteHook({
      queryKey: ["domain"],
      navigateURL: "",
    });
  const handleDeleteDomain = async (id: any) => {
    const url = `${APIS.DOMAIN}${id}/`;
    try {
      await deleteservice({ url });
    } catch (e) {
      console.log(e);
    }
  };
  return <DomainListComponent handleDeleteDomain={handleDeleteDomain} />;
};

export default DomainList;
