import loadable from "@loadable/component";
import { useState } from "react";
import { toast } from "react-toastify";
import CreateIcon from "../../Icons/CreateIcon";
import APIS from "../../constant/EndPoint";
import useGetHook from "../../customHooks/useGetHook";
import { postApiFormData } from "../../helpers/AxiosInstance";
import { useNavigate } from "react-router-dom";
const CreateClienttComponent = loadable(
  () => import("../../components/Client/CreateClient")
);
const CreateClient = () => {
  const [openColorUploadPopup, setColorUploadPopup] = useState<any>(false);
  const [defaultValue, setDefaultValue] = useState<any>();
  const navigate=useNavigate();
  const handleEditDomainChange = (value: any) => {
    setDefaultValue(value);
  };

  const { data: domainList } = useGetHook({
    queryKey: [`domain`],
    url: `${APIS.DOMAIN}`,
    params: {},
  });
  const handleCreate = async (values: any, setError: any) => {
    let formData = {
      ...values,
      domain: `${values?.domain}.careinfosolutions.com` 

    };
    try {
      let url = `${APIS.CLIENT}`;
      const res = await postApiFormData({ url, formData, setError });
      if (res.status === 201) {
        toast.success("New Client Created Successfully");
        navigate("/clients?limit=10&page=0")
      }
    } catch (e) {
      console.log(e);
      toast.error("Somethings went wrongs");
    }
  };
  return (
    <>
      <div
        className={`w-full flex justify-left gap-x-2 mb-6 uppercase font-extrabold items-center`}
      >
        <CreateIcon />
        <span className={`text-[#5E5757] font-bold text-xl`}>
          Create New Client
        </span>
      </div>
      <CreateClienttComponent
        openColorUploadPopup={openColorUploadPopup}
        setColorUploadPopup={setColorUploadPopup}
        handleCreate={handleCreate}
        handleEditDomainChange={handleEditDomainChange}
        defaultValue={defaultValue}
        domainList={domainList}
      />
    </>
  );
};

export default CreateClient;
