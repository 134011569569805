import loadable from "@loadable/component";
import { setKey as setLocalKey } from "../../helpers/sessionKey";
import usePostHook from "../../customHooks/usePostHook";
import APIS from "../../constant/EndPoint";

const LoginFormComponent = loadable(
  () => import("../../components/Auth/LoginForm")
);

const LoginPage = () => {
  const { mutateAsync, isPostLoading } = usePostHook({
    queryKey: [""],
  });

  const handleLogin = async (values: any, setError: any) => {
    const url = `${APIS.TOKEN}`;
    let formData = {
      ...values,
    };
    try {
      const res = await mutateAsync({ url, formData, setError });
      if (res.status === 200) {
        setLocalKey("token", res.data?.access);
        window.location.replace("/");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <LoginFormComponent
        handleLogin={handleLogin}
        isLoading={isPostLoading}
      />
    </>
  );
};

export default LoginPage;
