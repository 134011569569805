import loadable from '@loadable/component'
import StatusIcon from '../../Icons/StatusIcon'
import { useParams } from 'react-router-dom';
import usePostHook from '../../customHooks/usePostHook';
import useDeleteHook from '../../customHooks/useDeleteHook';
import APIS from '../../constant/EndPoint';
const ClientDomainComponent=loadable(
  ()=>import("../../components/Client/ClientLayout/ClientDomain")
)
const ClientDomain = () => {
  const {id}=useParams();
  const { mutateAsync: deleteservice, isLoading: isDeleteloading } =
    useDeleteHook({
      queryKey: ["domain"],
      navigateURL: "",
    });
  const handleDeleteDomain = async (id: any) => {
    const url = `${APIS.DOMAIN}${id}/`;
    try {
      await deleteservice({ url });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
        <div
        className={`w-full flex justify-left gap-x-2 mb-6 uppercase font-extrabold items-center`}
      >
        <StatusIcon />
        <span className={`text-[#5E5757] font-bold text-xl`}>
          Client Domain List
        </span>
      </div>
     <ClientDomainComponent id={id} handleDeleteDomain={handleDeleteDomain}/>
     </>
  )
}

export default ClientDomain
